/** LOGIN **/
.login h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #303032;
    margin-bottom: 20px;
}

.login h2 {
    color: #303032;
    border-bottom: 2px solid #abbed1;
    margin-bottom: 16px;
    padding-bottom: 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.login button {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.login .sign-up-button {
    margin-top: 16px;
}

.login .active-session-title {
    font-size: 24px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

@media (max-width: 768px) {
    .login h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        color: #303032;
        margin-bottom: 20px;
    }
}

.login .suggestion-change-password {
    width: 358px;
    max-width: 100%;
    background: white;
}

.login .suggestion-change-password h1 {
    margin-bottom: 16px;
}

.login .suggestion-change-password .message {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;
}

.login .suggestion-change-password .checkbox {
    border: 2px solid #005df9;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: transparent;
}

.login .suggestion-change-password .checked {
    background: #005df9;
}

.login .suggestion-change-password .footer {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.login .suggestion-change-password .footer .footer-message {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
}

/** LOGIN **/
